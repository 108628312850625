body {
  font-size: 20px;

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;

    @media (max-width: 640px) {
      font-size: 2rem;
      margin-top: 1rem;
    }
  }

  p {
    margin-bottom: 1.5rem;
  }

  .main-container {
    height: 100vh;

    @media (max-width: 640px) {
      height: 100%;
    }

    @media (max-height: 700px) {
      height: 100%;
    }
  }

  .dark & {
    @apply bg-gray-800;
  }
}

.content-container {
  @media (min-width: 640px) {
    flex: 1 1;
  }
}

.header {
  a {
    cursor: pointer;
  }
}

.nav {
  align-items: center;
  display: flex;
  gap: 10px;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  // margin-top: 1.5rem;

  .mastery {
    background: #5E4AE3;
    font-size: 20px;
    padding: 0.5rem 1rem;

    &:hover {
      background: #8272E9;
    }

    .light & {
      background: #50C9CE;

      &:hover {
        background: #70D3D7;
      }
    }
  }
}

.footer {
  margin: 2rem 0 1rem;
  padding: 1rem 0;
  text-align: center;

  .contact-icons {
    display: flex;
    justify-content: center;;
    gap: 2rem;
    margin-top: 1rem;

    i {
      font-size: 32px;
    }
  }

  @media (max-width: 640px) {
    margin-top: 0.5rem;
  }
}